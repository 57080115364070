$vi-font-path: '../assets/fonts/' !default;
$icomoon-font-family: 'videoindexer' !default;
$icomoon-font-path: 'fonts' !default;

$i-vi-thumbs-down: '\e926';
$i-vi-thumbs-up: '\e927';
$i-vi-brain: '\e921';
$i-vi-camera-explorer: '\e922';
$i-vi-hide: '\e923';
$i-vi-new: '\e924';
$i-vi-show: '\e925';
$i-vi-ai-sparkle: '\e920';
$i-vi-Bounding-box: '\e91b';
$i-vi-check-small: '\e91a';
$i-vi-train: '\e919';
$i-vi-person-box: '\e918';
$i-vi-account-settings: '\e913';
$i-vi-vol-mute: '\e90e';
$i-vi-vi-logo-big: '\e90b';
$i-vi-subselect: '\e90a';
$i-vi-editor: '\e908';
$i-vi-audio: '\e909';
$i-vi-customization: '\e905';
$i-vi-more: '\e900';
$i-vi-gripper: '\e907';
$i-vi-add: '\e906';
$i-vi-settings: '\e115';
$i-vi-mail: '\e119';
$i-vi-filter: '\e16e';
$i-vi-share: '\e72d';
$i-vi-layers: '\e81e';
$i-vi-link: '\e167';
$i-vi-back: '\e0a6';
$i-vi-privacy: '\e1f6';
$i-vi-user: '\e13d';
$i-vi-search: '\e094';
$i-vi-ok: '\e008';
$i-vi-trash: '\e107';
$i-vi-save: '\e105';
$i-vi-flag: '\e129';
$i-vi-file: '\e160';
$i-vi-null: '\e004';
$i-vi-previous: '\e100';
$i-vi-next: '\e101';
$i-vi-download: '\7b';
$i-vi-upload: '\e11c';
$i-vi-open: '\e2b4';
$i-vi-rename: '\e13e';
$i-vi-close-small: '\7a';
$i-vi-add-user: '\e1e2';
$i-vi-translate: '\e128';
$i-vi-select: '\e930';
$i-vi-info: '\e946';
$i-vi-arrow-up-small: '\e96d';
$i-vi-arrow-down-small: '\e228';
$i-vi-arrow-up: '\e971';
$i-vi-arrow-down: '\e972';
$i-vi-help: '\e9ce';
$i-vi-delete: '\ea39';
$i-vi-unselect: '\ea3a';
$i-vi-set-thumb: '\eb9f';
$i-vi-removefrom: '\ecc9';
$i-vi-addto: '\ecc8';
$i-vi-embed: '\ecce';
$i-vi-render: '\ef41';
$i-vi-filter-off: '\ef8f';
$i-vi-edit: '\ef9b';
$i-vi-insigtedit: '\f00f';
$i-vi-export: '\f0d4';
$i-vi-loading: '\f16a';
$i-vi-gotovid: '\f1c0';
$i-vi-quality-a: '\e90f';
$i-vi-quality-b: '\e910';
$i-vi-quality-c: '\e911';
$i-vi-quality-d: '\e912';
$i-vi-close-big: '\e10a';
$i-vi-fullscreen-off: '\e1d8';
$i-vi-fullscreen: '\e1d9';
$i-vi-block: '\e733';
$i-vi-play: '\e768';
$i-vi-index: '\e895';
$i-vi-grid-view: '\e8a9';
$i-vi-vid-speed: '\e916';
$i-vi-arrow-back-small: '\e973';
$i-vi-vol-low: '\e993';
$i-vi-vol-med: '\e994';
$i-vi-vol-high: '\e995';
$i-vi-list-view: '\ea37';
$i-vi-captions: '\ed1e';
$i-vi-sortup: '\ee68';
$i-vi-sortdown: '\ee69';
$i-vi-vid-pause: '\f6b0';
$i-vi-hamburger: '\e700';
$i-vi-add-big: '\e109';
$i-vi-forward: '\e0ab';
$i-vi-refresh: '\e149';
$i-vi-warning: '\e7ba';
$i-vi-copy: '\e7e9';
$i-vi-help-menu: '\e11b';
$i-vi-error: '\ea3b';
$i-vi-vi-accounts: '\ed0f';
$i-vi-content-hide: '\cede';
$i-vi-vid-play: '\edb5';
$i-vi-text-file: '\f029';
$i-vi-grab: '\f792';
$i-vi-live-feed: '\e714';
$i-vi-roles: '\e125';
$i-vi-arrow-right-small: '\e26b';
$i-vi-arrow-right: '\e975';
$i-vi-properties: '\e9f5';
$i-vi-configure: '\f01e';
$i-vi-select-cell: '\f13e';
$i-vi-import: '\e150';
$i-vi-connect-arm: '\e533';
$i-vi-play-big: '\eecf';
$i-vi-notification: '\ea8f';
$i-vi-chev-left: '\ee98';
$i-vi-chev-right: '\ee99';
$i-vi-account-prefs: '\ef58';
$i-vi-view: '\f0a8';
$i-vi-cloudupload: '\ec8e';
$i-vi-document-sparkle: '\e91f';
$i-vi-no-access: '\e917';
$i-vi-illustration-error: '\e914';
$i-vi-illustration-no-results: '\e915';
$i-vi-azure: '\e782';
$i-vi-google: '\e90c';
$i-vi-account: '\e90d';
$i-vi-facebook: '\e901';
$i-vi-linkedin: '\e903';
$i-vi-gplus: '\e902';
$i-vi-twitter: '\e904';
$i-vi-menu: '\e10c';
$i-vi-tag: '\e91c';
$i-vi-sort: '\e91d';
$i-vi-merge: '\e91e';
