@import 'variables';

@font-face {
  font-family: 'videoindexer';
  font-display: swap;
  src: url('#{$vi-font-path}/videoindexer.eot?ho2cv6');
  src: url('#{$vi-font-path}/videoindexer.eot?ho2cv6#iefix') format('embedded-opentype'),
    url('#{$vi-font-path}/videoindexer.ttf?ho2cv6') format('truetype'), url('#{$vi-font-path}/videoindexer.woff?ho2cv6') format('woff'),
    url('#{$vi-font-path}/videoindexer.svg?ho2cv6#videoindexer') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='i-vi-'],
[class*=' i-vi-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-vi-thumbs-down {
  &:before {
    content: $i-vi-thumbs-down;
  }
}
.i-vi-thumbs-up {
  &:before {
    content: $i-vi-thumbs-up;
  }
}
.i-vi-Bounding-box {
  &:before {
    content: $i-vi-Bounding-box;
  }
}
.i-vi-check-small {
  &:before {
    content: $i-vi-check-small;
  }
}
.i-vi-train {
  &:before {
    content: $i-vi-train;
  }
}
.i-vi-person-box {
  &:before {
    content: $i-vi-person-box;
  }
}
.i-vi-account-settings {
  &:before {
    content: $i-vi-account-settings;
  }
}
.i-vi-vol-mute {
  &:before {
    content: $i-vi-vol-mute;
  }
}
.i-vi-vi-logo-big {
  &:before {
    content: $i-vi-vi-logo-big;
  }
}
.i-vi-subselect {
  &:before {
    content: $i-vi-subselect;
  }
}
.i-vi-editor {
  &:before {
    content: $i-vi-editor;
  }
}
.i-vi-audio {
  &:before {
    content: $i-vi-audio;
  }
}
.i-vi-customization {
  &:before {
    content: $i-vi-customization;
  }
}
.i-vi-more {
  &:before {
    content: $i-vi-more;
  }
}
.i-vi-gripper {
  &:before {
    content: $i-vi-gripper;
  }
}
.i-vi-add {
  &:before {
    content: $i-vi-add;
  }
}
.i-vi-settings {
  &:before {
    content: $i-vi-settings;
  }
}
.i-vi-mail {
  &:before {
    content: $i-vi-mail;
  }
}
.i-vi-filter {
  &:before {
    content: $i-vi-filter;
  }
}
.i-vi-share {
  &:before {
    content: $i-vi-share;
  }
}
.i-vi-layers {
  &:before {
    content: $i-vi-layers;
  }
}
.i-vi-link {
  &:before {
    content: $i-vi-link;
  }
}
.i-vi-back {
  &:before {
    content: $i-vi-back;
  }
}
.i-vi-privacy {
  &:before {
    content: $i-vi-privacy;
  }
}
.i-vi-user {
  &:before {
    content: $i-vi-user;
  }
}
.i-vi-search {
  &:before {
    content: $i-vi-search;
  }
}
.i-vi-ok {
  &:before {
    content: $i-vi-ok;
  }
}
.i-vi-trash {
  &:before {
    content: $i-vi-trash;
  }
}
.i-vi-save {
  &:before {
    content: $i-vi-save;
  }
}
.i-vi-flag {
  &:before {
    content: $i-vi-flag;
  }
}
.i-vi-file {
  &:before {
    content: $i-vi-file;
  }
}
.i-vi-null {
  &:before {
    content: $i-vi-null;
  }
}
.i-vi-previous {
  &:before {
    content: $i-vi-previous;
  }
}
.i-vi-next {
  &:before {
    content: $i-vi-next;
  }
}
.i-vi-download {
  &:before {
    content: $i-vi-download;
  }
}
.i-vi-upload {
  &:before {
    content: $i-vi-upload;
  }
}
.i-vi-open {
  &:before {
    content: $i-vi-open;
  }
}
.i-vi-rename {
  &:before {
    content: $i-vi-rename;
  }
}
.i-vi-close-small {
  &:before {
    content: $i-vi-close-small;
  }
}
.i-vi-add-user {
  &:before {
    content: $i-vi-add-user;
  }
}
.i-vi-translate {
  &:before {
    content: $i-vi-translate;
  }
}
.i-vi-select {
  &:before {
    content: $i-vi-select;
  }
}
.i-vi-info {
  &:before {
    content: $i-vi-info;
  }
}
.i-vi-arrow-up-small {
  &:before {
    content: $i-vi-arrow-up-small;
  }
}
.i-vi-arrow-down-small {
  &:before {
    content: $i-vi-arrow-down-small;
  }
}
.i-vi-arrow-up {
  &:before {
    content: $i-vi-arrow-up;
  }
}
.i-vi-arrow-down {
  &:before {
    content: $i-vi-arrow-down;
  }
}
.i-vi-help {
  &:before {
    content: $i-vi-help;
  }
}
.i-vi-delete {
  &:before {
    content: $i-vi-delete;
  }
}
.i-vi-unselect {
  &:before {
    content: $i-vi-unselect;
  }
}
.i-vi-set-thumb {
  &:before {
    content: $i-vi-set-thumb;
  }
}
.i-vi-removefrom {
  &:before {
    content: $i-vi-removefrom;
  }
}
.i-vi-addto {
  &:before {
    content: $i-vi-addto;
  }
}
.i-vi-embed {
  &:before {
    content: $i-vi-embed;
  }
}
.i-vi-render {
  &:before {
    content: $i-vi-render;
  }
}
.i-vi-filter-off {
  &:before {
    content: $i-vi-filter-off;
  }
}
.i-vi-edit {
  &:before {
    content: $i-vi-edit;
  }
}
.i-vi-insigtedit {
  &:before {
    content: $i-vi-insigtedit;
  }
}
.i-vi-export {
  &:before {
    content: $i-vi-export;
  }
}
.i-vi-loading {
  &:before {
    content: $i-vi-loading;
  }
}
.i-vi-gotovid {
  &:before {
    content: $i-vi-gotovid;
  }
}
.i-vi-quality-a {
  &:before {
    content: $i-vi-quality-a;
  }
}
.i-vi-quality-b {
  &:before {
    content: $i-vi-quality-b;
  }
}
.i-vi-quality-c {
  &:before {
    content: $i-vi-quality-c;
  }
}
.i-vi-quality-d {
  &:before {
    content: $i-vi-quality-d;
  }
}
.i-vi-close-big {
  &:before {
    content: $i-vi-close-big;
  }
}
.i-vi-fullscreen-off {
  &:before {
    content: $i-vi-fullscreen-off;
  }
}
.i-vi-fullscreen {
  &:before {
    content: $i-vi-fullscreen;
  }
}
.i-vi-block {
  &:before {
    content: $i-vi-block;
  }
}
.i-vi-play {
  &:before {
    content: $i-vi-play;
  }
}
.i-vi-index {
  &:before {
    content: $i-vi-index;
  }
}
.i-vi-grid-view {
  &:before {
    content: $i-vi-grid-view;
  }
}
.i-vi-vid-speed {
  &:before {
    content: $i-vi-vid-speed;
  }
}
.i-vi-arrow-back-small {
  &:before {
    content: $i-vi-arrow-back-small;
  }
}
.i-vi-vol-low {
  &:before {
    content: $i-vi-vol-low;
  }
}
.i-vi-vol-med {
  &:before {
    content: $i-vi-vol-med;
  }
}
.i-vi-vol-high {
  &:before {
    content: $i-vi-vol-high;
  }
}
.i-vi-list-view {
  &:before {
    content: $i-vi-list-view;
  }
}
.i-vi-captions {
  &:before {
    content: $i-vi-captions;
  }
}
.i-vi-sortup {
  &:before {
    content: $i-vi-sortup;
  }
}
.i-vi-sortdown {
  &:before {
    content: $i-vi-sortdown;
  }
}
.i-vi-vid-pause {
  &:before {
    content: $i-vi-vid-pause;
  }
}
.i-vi-hamburger {
  &:before {
    content: $i-vi-hamburger;
  }
}
.i-vi-add-big {
  &:before {
    content: $i-vi-add-big;
  }
}
.i-vi-forward {
  &:before {
    content: $i-vi-forward;
  }
}
.i-vi-refresh {
  &:before {
    content: $i-vi-refresh;
  }
}
.i-vi-warning {
  &:before {
    content: $i-vi-warning;
  }
}
.i-vi-copy {
  &:before {
    content: $i-vi-copy;
  }
}
.i-vi-help-menu {
  &:before {
    content: $i-vi-help-menu;
  }
}
.i-vi-error {
  &:before {
    content: $i-vi-error;
  }
}
.i-vi-vi-accounts {
  &:before {
    content: $i-vi-vi-accounts;
  }
}
.i-vi-content-hide {
  &:before {
    content: $i-vi-content-hide;
  }
}
.i-vi-vid-play {
  &:before {
    content: $i-vi-vid-play;
  }
}
.i-vi-text-file {
  &:before {
    content: $i-vi-text-file;
  }
}
.i-vi-grab {
  &:before {
    content: $i-vi-grab;
  }
}
.i-vi-live-feed {
  &:before {
    content: $i-vi-live-feed;
  }
}
.i-vi-roles {
  &:before {
    content: $i-vi-roles;
  }
}
.i-vi-arrow-right-small {
  &:before {
    content: $i-vi-arrow-right-small;
  }
}
.i-vi-arrow-right {
  &:before {
    content: $i-vi-arrow-right;
  }
}
.i-vi-properties {
  &:before {
    content: $i-vi-properties;
  }
}
.i-vi-configure {
  &:before {
    content: $i-vi-configure;
  }
}
.i-vi-select-cell {
  &:before {
    content: $i-vi-select-cell;
  }
}
.i-vi-import {
  &:before {
    content: $i-vi-import;
  }
}
.i-vi-connect-arm {
  &:before {
    content: $i-vi-connect-arm;
  }
}
.i-vi-play-big {
  &:before {
    content: $i-vi-play-big;
  }
}
.i-vi-notification {
  &:before {
    content: $i-vi-notification;
  }
}
.i-vi-chev-left {
  &:before {
    content: $i-vi-chev-left;
  }
}
.i-vi-chev-right {
  &:before {
    content: $i-vi-chev-right;
  }
}
.i-vi-account-prefs {
  &:before {
    content: $i-vi-account-prefs;
  }
}
.i-vi-view {
  &:before {
    content: $i-vi-view;
  }
}
.i-vi-cloudupload {
  &:before {
    content: $i-vi-cloudupload;
  }
}
.i-vi-no-access {
  &:before {
    content: $i-vi-no-access;
  }
}
.i-vi-illustration-error {
  &:before {
    content: $i-vi-illustration-error;
  }
}
.i-vi-illustration-no-results {
  &:before {
    content: $i-vi-illustration-no-results;
  }
}
.i-vi-azure {
  &:before {
    content: $i-vi-azure;
  }
}
.i-vi-google {
  &:before {
    content: $i-vi-google;
  }
}
.i-vi-account {
  &:before {
    content: $i-vi-account;
  }
}
.i-vi-facebook {
  &:before {
    content: $i-vi-facebook;
  }
}
.i-vi-linkedin {
  &:before {
    content: $i-vi-linkedin;
  }
}
.i-vi-gplus {
  &:before {
    content: $i-vi-gplus;
  }
}
.i-vi-twitter {
  &:before {
    content: $i-vi-twitter;
  }
}
.i-vi-menu {
  &:before {
    content: $i-vi-menu;
  }
}
.i-vi-tag {
  &:before {
    content: $i-vi-tag;
  }
}
.i-vi-sort {
  &:before {
    content: $i-vi-sort;
  }
}
.i-vi-merge {
  &:before {
    content: $i-vi-merge;
  }
}
.i-vi-brain:before {
  content: $i-vi-brain;
}
.i-vi-camera-explorer:before {
  content: $i-vi-camera-explorer;
}
.i-vi-hide:before {
  content: $i-vi-hide;
}
.i-vi-new:before {
  content: $i-vi-new;
}
.i-vi-show:before {
  content: $i-vi-show;
}
.i-vi-ai-sparkle:before {
  content: $i-vi-ai-sparkle;
}
.i-vi-document-sparkle:before {
  content: $i-vi-document-sparkle;
}
